<template>

    <!-- 多选上传图片 -->

    <div class="m-img-upload" >

      <!-- 上传图片显示 -->
      <div class="img-wrapper" v-show="image.url != ''" >
          <img :src="image.url" alt="logo" >
          <div class="img-edit" >
              <img src="./../../assets/public/delete.png" @click.stop="handleRemove(image.pic_id)" alt="Delete">
          </div>
      </div>

        <!--  上传输入框 -->
      <label v-show="image.url == ''"   class="input-label">
        <van-loading v-if="load"  size="24" color="#1989fa" />
        <div v-else>
          <van-icon name="plus" :color="color" size="1.5em"  />
          <span>{{text}}</span>
        </div>
        <input @change="handleChange($event)" type="file" accept="image/jpg,image/jpeg,image/png" multiple="multiple">
      </label>


    



    </div>
      
    



</template>

<script>
import { upload, delpic } from '@/api/public.js'

export default {
  name:"Upload",
  props:{
    fileName:{
      type:String,
      default:'',
      required:true,
    },
    text: {
      type:String,
      default: '',
    },
    color: {
      type: String,
      default: '#1989fa'
    },
    img: {
      type: Object,
      default: () => {},
      required: true
    },
  },

  data() {
    return {
      image: this.img,
      load: false,
    }
  },


  methods:{

    handleChange(e){
      let file = e.target.files;
      console.log(file)
      var image = new FormData()
      image.append('filename', this.fileName)
      image.append('file', file[0])
      this.load = true
      upload(image).then(res => {
        if(res){
          this.image  = res.data
          this.$emit('update:img', this.image)
        }
      }).finally(() => {
        this.load = false
      })

    },

    handleRemove(id) {
      if(id != "") {
        delpic(id).then( res => {
          if(res) {
            this.image = { url: '', pic_id: '' }
            this.$emit('update:img', this.image)
          }
        })
      }
    },




  },
}
</script>

<style lang="less" scoped>
.m-img-upload{
  display: inline-block;
    .img-wrapper {
      display: inline-block;
      position: relative;
      border-radius: 5px;
      margin-right: 20px;
      margin-bottom: 12px;
      width: 94px;
      height: 94px;
      img{ 
          object-fit: cover;
          border-radius: 4px;
          width: 100%;
          height: 100%;
      }
      .img-edit {
          position: absolute;
          top: -8px;
          right: -8px;
          >img {
            width: 14px;
            height: 14px;
          }
      }
    }
  .input-label {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #B5B5B5;
    border-radius: 5px;
    width: 94px;
    height: 94px;
    position: relative;
    >div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      >span {
        font-family: PingFangSC;
        font-size: 12px;
        line-height: 14px;
        color: #999999;
        margin-top: 8px;
      }
    }
    >input {
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0;
    }
  }
}
.m-img-upload + .m-img-upload {
  margin-left: 20px;
}
  
</style>